<template>
  <div class="navbar-container d-flex align-items-center">

    <!-- Nav Menu Toggler -->
    <!--<ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>-->

    <!-- Left Col -->
    <div>
      <img src="../../assets/images/logo/logo.png" alt="" style="width: 200px;">
    </div>
    <!--<div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>-->

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">{{userName}}</p>
            <!--<span class="user-status">Admin</span>-->
          </div>
          <span style="display: inline-block;background-color: #b8c2cc;padding: 6px;border-radius: 100%;color: white"><feather-icon icon="UserIcon" size="21"/></span>

          <!--<b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/13-small.png')"
            class="badge-minimal"
            badge-variant="success"
          />-->
        </template>

        <b-dropdown-item link-class="d-flex align-items-center" @click="logOut">
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data(){
    return{
      userName:null
    }
  },
  methods:{
    logOut() { //退出
      localStorage.removeItem('contactId');
      localStorage.removeItem('username');
      this.$router.push({path: '/login'});
    },
  },
  mounted(){
    this.userName = localStorage.getItem('username')
  }
}
</script>
